import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[appTimeMask]'
})
export class TimeDirective {
  @Input() appTimeMask: string = '00:00'; // Default to '00:00' if not provided

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let sanitizedValue = input.value.replace(/[^0-9]/g, '');

    // Limit to 4 digits
    if (sanitizedValue.length > 4) {
      sanitizedValue = sanitizedValue.substring(0, 4);
    }

    // Format only if exactly 4 digits are present
    if (sanitizedValue.length === 4) {
      const minutes = parseInt(sanitizedValue.substring(0, 2), 10);
      const seconds = parseInt(sanitizedValue.substring(2, 4), 10);

      if (minutes > 59 || seconds > 59) {
        input.value = this.appTimeMask; // Invalid time, set to default mask (e.g., "00:00")
      } else {
        input.value = `${sanitizedValue.substring(0, 2)}:${sanitizedValue.substring(2, 4)}`;
      }
    }
    // If only 2 digits are entered, leave it as is and let the user continue typing
    else if (sanitizedValue.length === 2) {
      input.value = sanitizedValue;
    }
    // If less than 2 digits, show the value directly
    else {
      input.value = sanitizedValue;
    }
  }
}