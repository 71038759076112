import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { BehaviorSubject } from "rxjs";
import { mergeMapTo } from 'rxjs/operators';
import { Constant } from "../providers/constant";
import { HttpRequestsService } from "./http-requests.service";
import { LocalStorageService } from "./local-storage.service";
import { UtilsService } from "./utils.service";
@Injectable()
export class MessagingService {
	currentMessage = new BehaviorSubject(null);
	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private utility: UtilsService,
		private httpRequest: HttpRequestsService,
		private localstorage: LocalStorageService
	) {
		this.angularFireMessaging.messages.subscribe((_messaging) => {
			this.angularFireMessaging.onMessage = this.angularFireMessaging.onMessage.bind(_messaging)
			this.angularFireMessaging.tokenChanges.subscribe(async (token) => {
				await this.updateToken(token)
			})
			// _messaging.onMessage = _messaging.onMessage.bind(_messaging);
			// _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
		});
	}
	requestPermission() {
        this.angularFireMessaging.requestPermission
		.pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
		.subscribe(
			async(token) => { 
				await this.updateToken(token)
			},
			(error) => { console.error(error); },
		);
	}
	receiveMessage() {
		this.angularFireMessaging.messages.subscribe((payload: any) => {
			this.utility.sendBadgeUpdate(payload.data.badge)
			const chatRoomId = localStorage.getItem("chatRoomId");
			if(payload.data.chatRoomId) {
				if(Number(payload.data.chatRoomId) !== Number(chatRoomId)) {
					// this.utility.showSuccessToastwithTitle(payload.notification.title, payload.notification.body);
					this.utility.sendNotificatioUpdate(true, "newMessage")
					this.utility.sendUpdateRequest(Constant.UPDATE_SENT_TYPE.READ_MESSAGE)
				}
			} 
			// else {
			// 	this.utility.showSuccessToast(payload.notification.body);
				
			// }
			if(payload.data.notificationType === Constant.NOTIFICATION_TYPE.SENT_REQUEST) {
				this.utility.sendNotificatioUpdate(true, payload.data.notificationType)
			}
			// this.utility.updateNotification();
			this.utility.log('new message received. ', payload);
			this.currentMessage.next(payload);
		});
	}

	async updateToken(token) {
		const data = {
			webFcmToken: token
		}
		const userToken = this.localstorage.getLocalStore('token');
		if(userToken !== null) {
			await this.httpRequest.post(Constant.USER_API_ROUTES.UPDATE_FCM_TOKEN, data);
		}
	}
}
