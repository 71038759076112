import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonAPIService } from 'src/app/shared/common-api.service';
import { Constant } from 'src/app/shared/providers/constant';
import { HttpRequestsService } from 'src/app/shared/services/http-requests.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import Swal from 'sweetalert2';
import videojs from 'video.js';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  isExpand: boolean = false;
  @Input() data: any;
  pageNumber: number = 1;

  totalRecord: number;
  eventData: any;
  totalParticipant: any;
  userId: any;
  showTxt = "...More";
  firstCount = 100
  counter = 100;
  last_index = 100;
  userDetails: any;
  videoAudioEnabled: boolean = false;
  isVideoPause: boolean;
  public screen: ElementRef;
  @ViewChild('screen') set content(content: ElementRef) {
    if (content) {
      if (this.eventData.videoUrl && !this.eventData.isSubscriberOnly) {
        let player = videojs(document.getElementById('myVideo') as Element);
        player.play()
      }
    }
  }
  constructor(
    public modalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    public utils: UtilsService,
    public common: CommonAPIService,
    private httpRequest: HttpRequestsService,
    private toastr: ToastrService,
    private router: Router,
    private localstorege: LocalStorageService,
    private websocketService: WebsocketService,
    private translate: TranslateService,
  ) {
    const storageValue: any = this.localstorege.getLocalStore(Constant.LOCAL_STORAGE_KEY.USER_DETAILS);
    this.userDetails = JSON.parse(storageValue);
  }

  async ngOnInit() {
    this.userId = this.userDetails.id;
    await this.eventDetails();
    if (this.userId === this.eventData.userId) {
      await this.joinRoomForEvent();
      await this.listenEventStartEnable();
    } else {
      await this.enableJoinNow();
    }
    await this.userDeleteListen();
  }

  async userDeleteListen() {
    this.websocketService.listen(Constant.SOCKET_EVENTS.USER_DELETED).subscribe(async (data: any) => {
      if (data.isUserDeleted && data.userId === this.eventData.userId) {
        this.modalRef.hide();
        this.router.navigate(['/events'])
        this.toastr.error(this.translate.instant(data.message))
      }
    });
  }

  async enableJoinNow() {
    this.websocketService.listen(Constant.SOCKET_EVENTS.JOIN_EVENT).subscribe((data: any) => {
      if (data) {
        if (this.eventData.id === data.eventId) {
          this.eventData.isEventStarted = data.event.isEventStarted
        }
      }
    });
  }

  async joinRoomForEvent() {
    const data = {
      userId: this.userId
    }
    await this.common.joinRoomForEvent(data)
  }

  async listenEventStartEnable() {
    this.websocketService.listen(Constant.SOCKET_EVENTS.EVENT_START_ENABLE).subscribe((data: any) => {
      if (data) {
        if (this.eventData.id === data.id) {
          this.eventData.isStartNow = data.isStartNow
        }
      }
    });
  }

  async startEvent(item) {
    const result = await this.common.startEvent(item);
  }

  async joinEvent(eventDetails) {
    const data = {
      isLeave: false,
      eventId: eventDetails.id,
      userId: this.userDetails.id,
    }
    await this.common.joinEventAPI(data);
  }

  async eventDetails() {
    this.spinner.show();
    let data = {
      "eventId": this.data.id,
      "page": this.pageNumber,
      "limit": Constant.LIMIT,
      "isLive": false
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.EVENTDETAILS, data);
    if (result) {
      this.eventData = result.data.eventDetails;
      const eventDate = moment(this.eventData.eventDate).format(Constant.DATE_FORMAT);
      this.eventData.eventTime = this.utils.tConvert(moment.utc(`${eventDate} ${this.eventData.eventTime}`).local().format(Constant.TIME_FORMAT_HH_MM));
      this.totalParticipant = result.data.totalParticipant;
      this.spinner.hide();
    }
  }
  async AttendEvent(item) {
    this.spinner.show();
    if (item.isAllowAttend) {
      let data = {
        "isAttend": true,
        "eventId": item.id,
        "creatorId": item.userId
      }
      const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.ATTENDEVENT, data);
      if (result) {
        this.toastr.success(result['message']);
        this.spinner.hide();
        this.eventDetails();
        return result;
      }
    } else {
      Swal.fire({
        title: 'Event already started you cant attend the event now.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {

        }
      })
    }
  }
  removeAttend(item) {
    Swal.fire({
      title: 'Are you want to remove from event?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ffb200',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.removeEventAttend(item);
      }
    })
  }
  async removeEventAttend(item) {
    let data = {
      "isAttend": false,
      "eventId": item.id,
      "creatorId": item.userId
    }
    const result: any = await this.httpRequest.post(Constant.USER_API_ROUTES.ATTENDEVENT, data);
    if (result) {
      this.toastr.warning(result['message']);
      this.spinner.hide();
      this.eventDetails();
      return result;
    }
  }

  // muteVideo() {
  //   const video = videojs(document.getElementById(`myVideo`) as Element);
  //   if(video.muted()) {
  //     video.muted(false);
  //     this.videoAudioEnabled = false;
  //   } else {
  //     video.muted(true);
  //     this.videoAudioEnabled = true;

  //   }
  // }

  // playPause() {
  //   const video = videojs(document.getElementById(`myVideo`) as Element);
  //   const videoStatus =  video.paused();
  //   // console.log(videoStatus);

  //   if(videoStatus) {
  //     video.play();
  //     this.isVideoPause = false;
  //   } else {
  //     video.pause()
  //     this.isVideoPause = true;
  //   }
  // }

  toggleSkil(info) {
    if (this.counter < 101) {
      this.counter = info.length;
      this.showTxt = "...Less";
      this.isExpand = true;
    }
    else {
      this.counter = this.last_index;
      this.showTxt = "...More";
      this.isExpand = false;
    }
  }

  async ngOnDestroy() {
    this.websocketService.stopListen(Constant.SOCKET_EVENTS.JOIN_EVENT);
    this.websocketService.stopListen(Constant.SOCKET_EVENTS.EVENT_START_ENABLE)
  }

  subscribeFirst() {
    this.toastr.error(this.translate.instant('SUBSCRIBE_FIRST'))
    this.modalRef.hide();
    this.router.navigate([`/other-user/${this.eventData.userId}`], { queryParams: { tabId: 'Subscription Plan' } });
  }

  closeModel() {
    this.modalRef?.hide();
    // this.utils.back();
  }
}
